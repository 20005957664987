import React, { useState, useEffect, useContext, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Button, Dropdown } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import { useNavigate } from "react-router-dom"
import { MerchantContext } from "src/context/Merchant"
import { RouteType } from "src/constants/routeTypes"
import { useInitiateWebLinkRefund } from "src/api/user"
import { getSanitizedString } from "src/helpers/DomPurifyHelper"
import env, { ENV } from "src/env"
import BlockUI from "src/helpers/BlockUI"
import { useGetExchangeRate } from "src/api/onRamp"
import { REFUND_AMOUNT_LIMIT } from "src/constants/Defaults"
import DOMPurify from "dompurify"

import CustomerVeriRefundPopupModal from "./CustomerVeriRefundPopupModal"

function InitiateRefundPopupModal(props: any) {
  const { merchantDetails } = useContext(MerchantContext)
  const { txt, id, color, disabled, rowtrx, apicallstatus } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [showCustomerForm, setShowCustomerForm] = useState(false)
  const [loaderShow, setLoaderShow] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState("")
  const [refundAmountLimit, setRefundAmountLimit] =
    useState(REFUND_AMOUNT_LIMIT)
  const [initiateData, setInitiateData] = useState({
    refundUserMobile:
      rowtrx.refundStatus === "REFUND_CANCELED" ||
      rowtrx.refundStatus === "REFUND_FAILED" ||
      rowtrx.refundStatus === "REFUND_EXPIRED"
        ? rowtrx.refundUserMobile == null
          ? ""
          : rowtrx.refundUserMobile.includes("+91")
          ? rowtrx.refundUserMobile.split("+91")[1]
          : rowtrx.refundUserMobile.includes("+61")
          ? rowtrx.refundUserMobile.split("+61")[1]
          : rowtrx.refundUserMobile.includes("+971")
          ? rowtrx.refundUserMobile.split("+971")[1]
          : ""
        : "",
    refundUserMobileCountryCode:
      rowtrx.refundStatus === "REFUND_CANCELED" ||
      rowtrx.refundStatus === "REFUND_FAILED" ||
      rowtrx.refundStatus === "REFUND_EXPIRED"
        ? rowtrx.refundUserMobile === null
          ? ""
          : rowtrx.refundUserMobile.includes("+91")
          ? "+91"
          : rowtrx.refundUserMobile.includes("+61")
          ? "+61"
          : rowtrx.refundUserMobile.includes("+971")
          ? "+971"
          : ""
        : "",
    refundUserEmail:
      rowtrx.refundStatus === "REFUND_CANCELED" ||
      rowtrx.refundStatus === "REFUND_FAILED" ||
      rowtrx.refundStatus === "REFUND_EXPIRED"
        ? rowtrx.refundUserEmail == null
          ? ""
          : rowtrx.refundUserEmail
        : "",
    totalFiatReceived: rowtrx.totalFiatReceived,
    refundAmountFiat:
      rowtrx.refundStatus == "REFUND_EXPIRED" ||
      rowtrx.refundStatus == "REFUND_CANCELED" ||
      rowtrx.refundStatus == "REFUND_FAILED"
        ? rowtrx.refundFiatAmount
        : rowtrx.balanceAmountFiat > 0
        ? rowtrx.balanceAmountFiat
        : rowtrx.totalFiatReceived,
    balanceAmountFiat: "",
    refundMode: "CASH"
  })
  const [serverErrorMessage, setServerErrorMessage] = useState<string>()

  const {
    mutate: submitInitiateRefundTransaction,
    error,
    isSuccess
  } = useInitiateWebLinkRefund()
  const digitalAsset =
    env.TYPE == ENV.DEV || env.TYPE == ENV.TESTING ? "BTC" : "USDT"
  const { data: exchangeRatesData, isFetching: isFetchingExchangeRates } =
    useGetExchangeRate(rowtrx.fiatAsset)

  const inputPhoneCodeRef = useRef<HTMLSelectElement>(null)
  const inputPhoneRef = useRef<HTMLInputElement>(null)
  const inputEmailRef = useRef<HTMLInputElement>(null)

  /*  useEffect(() => {
    if (merchantDetails?.merchant) {
      setRefundAmountLimit(merchantDetails.merchant.defaultRefundableFiatValue)
    }
  }, [merchantDetails]) */

  useEffect(() => {
    if (isSuccess) {
      setLoaderShow(true)
      setTimeout(() => {
        setShow(false)
        apicallstatus()
      }, 2000)
    }
  }, [isSuccess])

  useEffect(() => {
    if (error?.message) {
      setLoaderShow(false)
      setServerErrorMessage(error?.message)
    }
  }, [error])

  useEffect(() => {
    if (rowtrx) {
      if (
        rowtrx.totalFiatReceived &&
        rowtrx.totalFiatReceived > refundAmountLimit
      ) {
        setInitiateData({ ...initiateData, refundMode: "WALLET" })
      }
    }
  }, [rowtrx])

  const handleClose = () => {
    setInitiateData({
      ...initiateData,
      refundAmountFiat:
        rowtrx.refundStatus == "REFUND_CANCELED" ||
        rowtrx.refundStatus == "REFUND_FAILED" ||
        rowtrx.refundStatus === "REFUND_EXPIRED"
          ? rowtrx.refundFiatAmount
          : rowtrx.totalFiatReceived,
      refundUserEmail:
        rowtrx.refundStatus === "REFUND_CANCELED" ||
        rowtrx.refundStatus === "REFUND_FAILED" ||
        rowtrx.refundStatus === "REFUND_EXPIRED"
          ? rowtrx.refundUserEmail == null
            ? ""
            : rowtrx.refundUserEmail
          : "",
      refundUserMobile:
        rowtrx.refundStatus === "REFUND_CANCELED" ||
        rowtrx.refundStatus === "REFUND_FAILED" ||
        rowtrx.refundStatus === "REFUND_EXPIRED"
          ? rowtrx.refundUserMobile == null
            ? ""
            : rowtrx.refundUserMobile.includes("+91")
            ? rowtrx.refundUserMobile.split("+91")[1]
            : rowtrx.refundUserMobile.includes("+61")
            ? rowtrx.refundUserMobile.split("+61")[1]
            : rowtrx.refundUserMobile.includes("+971")
            ? rowtrx.refundUserMobile.split("+971")[1]
            : ""
          : "",
      refundUserMobileCountryCode:
        rowtrx.refundStatus === "REFUND_CANCELED" ||
        rowtrx.refundStatus === "REFUND_FAILED" ||
        rowtrx.refundStatus === "REFUND_EXPIRED"
          ? rowtrx.refundUserMobile === null
            ? ""
            : rowtrx.refundUserMobile.includes("+91")
            ? "+91"
            : rowtrx.refundUserMobile.includes("+61")
            ? "+61"
            : rowtrx.refundUserMobile.includes("+971")
            ? "+971"
            : ""
          : ""
    })
    setShow(false)
    setServerErrorMessage("")
  }
  const handleShow = () => setShow(true)

  function renderRefundAmount() {
    if (exchangeRatesData && initiateData) {
      const value =
        exchangeRatesData[digitalAsset] * initiateData.refundAmountFiat
      const finalVal = value.toFixed(8)
      return parseFloat(finalVal)
    }
  }

  function submitData() {
    // console.log(id)
    if (initiateData.refundAmountFiat > refundAmountLimit) {
      if (
        initiateData.refundUserMobile === "" &&
        initiateData.refundUserEmail === ""
      ) {
        setFormErrorMessage(
          "Fill Mobile Number or Email Address or Enter Customers Info Manually"
        )
        setTimeout(() => {
          setFormErrorMessage("")
        }, 2000)
        return
      }
    }
    if (
      initiateData.refundUserMobile !== "" &&
      initiateData.refundUserMobileCountryCode === ""
    ) {
      setFormErrorMessage("Select Mobile Code")
      setTimeout(() => {
        setFormErrorMessage("")
      }, 2000)
      return
    }
    if (initiateData.refundAmountFiat == 0) {
      setFormErrorMessage("Refund amount should be greater than 0")
      setTimeout(() => {
        setFormErrorMessage("")
      }, 2000)
      return
    }
    // const obj = {
    //   refundUserMobile:
    //     initiateData.refundUserMobileCountryCode +
    //       initiateData.refundUserMobile ===
    //     ""
    //       ? null
    //       : initiateData.refundUserMobileCountryCode +
    //         initiateData.refundUserMobile,
    //   refundUserEmail:
    //     initiateData.refundUserEmail === ""
    //       ? null
    //       : initiateData.refundUserEmail,
    //   refundDigitalType: rowtrx.asset,
    //   refundFiatType: rowtrx.fiatAsset,
    //   refundAmountFiat: initiateData.refundAmountFiat,
    //   transactionId: rowtrx.uuid,
    //   refundMode: initiateData.refundMode,
    //   refundCustomerFormUrl:
    //     window.location.protocol + "//" + window.location.hostname + "/",
    //   refundAmountDigital: renderRefundAmount() || 0
    // }
    // console.log(obj)
    if (rowtrx.totalFiatReceived <= refundAmountLimit) {
      setLoaderShow(true)
      submitInitiateRefundTransaction({
        refundDigitalType: rowtrx.asset,
        refundFiatType: rowtrx.fiatAsset,
        refundAmountFiat: initiateData.refundAmountFiat,
        balanceAmountFiat:
          initiateData.totalFiatReceived - initiateData.refundAmountFiat,
        transactionId: rowtrx.uuid,
        refundMode: initiateData.refundMode,
        refundAmountDigital: renderRefundAmount() || 0,
        refundCustomerFormUrl:
          window.location.protocol + "//" + window.location.hostname + "/",
        isReInitiateRefund:
          rowtrx.refundStatus == "REFUND_CANCELED" ||
          rowtrx.refundStatus == "REFUND_FAILED"
            ? true
            : false,
        refundTransactionID:
          rowtrx.refundStatus == "REFUND_CANCELED" ||
          rowtrx.refundStatus == "REFUND_FAILED"
            ? rowtrx.refundTransactionID
            : null
      })
    } else {
      setLoaderShow(true)
      submitInitiateRefundTransaction({
        refundUserMobile:
          initiateData.refundUserMobileCountryCode +
            initiateData.refundUserMobile ===
          ""
            ? null
            : initiateData.refundUserMobileCountryCode +
              initiateData.refundUserMobile,
        refundUserEmail:
          initiateData.refundUserEmail === ""
            ? null
            : initiateData.refundUserEmail,
        refundDigitalType: rowtrx.asset,
        refundFiatType: rowtrx.fiatAsset,
        refundAmountFiat: initiateData.refundAmountFiat,
        balanceAmountFiat:
          initiateData.totalFiatReceived - initiateData.refundAmountFiat,
        transactionId: rowtrx.uuid,
        refundMode:
          initiateData.refundAmountFiat <= refundAmountLimit
            ? "CASH"
            : "WALLET",
        refundCustomerFormUrl:
          window.location.protocol + "//" + window.location.hostname + "/",
        refundAmountDigital: renderRefundAmount() || 0,
        isReInitiateRefund:
          rowtrx.refundStatus == "REFUND_CANCELED" ||
          rowtrx.refundStatus == "REFUND_FAILED" ||
          rowtrx.refundStatus == "REFUND_EXPIRED"
            ? true
            : false,
        refundTransactionID:
          rowtrx.refundStatus == "REFUND_CANCELED" ||
          rowtrx.refundStatus == "REFUND_FAILED" ||
          rowtrx.refundStatus == "REFUND_EXPIRED"
            ? rowtrx.refundTransactionID.toString()
            : null
      })
    }
  }

  function storeData(e: any, input: any) {
    switch (input) {
      case "refundAmountFiat":
        console.log(e.target.value)
        if (!isNaN(e.target.value)) {
          setInitiateData({
            ...initiateData,
            refundAmountFiat: getSanitizedString(e.target.value)
          })
        }
        break
      case "mobileCode":
        {
          setInitiateData({
            ...initiateData,
            refundUserMobileCountryCode: getSanitizedString(e.target.value)
          })
        }
        break
      case "mobileNumber":
        {
          const sanitizedInput = DOMPurify.sanitize(e.target.value)
          setInitiateData({ ...initiateData, refundUserMobile: sanitizedInput })
        }
        break
      case "email":
        {
          const sanitizedInput = DOMPurify.sanitize(e.target.value)
          setInitiateData({ ...initiateData, refundUserEmail: sanitizedInput })
        }
        break
      default:
        break
    }
  }

  const clearData = () => {
    if (
      rowtrx.refundStatus == "REFUND_CANCELED" ||
      rowtrx.refundStatus == "REFUND_FAILED" ||
      rowtrx.refundStatus === "REFUND_EXPIRED"
    ) {
      return ""
    } else {
      setInitiateData({
        ...initiateData,
        refundAmountFiat: "",
        refundUserMobileCountryCode: "",
        refundUserMobile: "",
        refundUserEmail: ""
      })
    }

    if (inputPhoneCodeRef?.current) {
      if (
        rowtrx.refundStatus == "REFUND_CANCELED" ||
        rowtrx.refundStatus == "REFUND_FAILED" ||
        rowtrx.refundStatus === "REFUND_EXPIRED"
      ) {
        return ""
      } else {
        inputPhoneCodeRef.current.value = ""
      }
    }
    if (inputPhoneRef?.current) {
      if (
        rowtrx.refundStatus == "REFUND_CANCELED" ||
        rowtrx.refundStatus == "REFUND_FAILED" ||
        rowtrx.refundStatus === "REFUND_EXPIRED"
      ) {
        return ""
      } else {
        inputPhoneRef.current.value = ""
      }
    }
    if (inputEmailRef?.current) {
      if (
        rowtrx.refundStatus == "REFUND_CANCELED" ||
        rowtrx.refundStatus == "REFUND_FAILED" ||
        rowtrx.refundStatus === "REFUND_EXPIRED"
      ) {
        return ""
      } else {
        inputEmailRef.current.value = ""
      }
    }
  }

  return (
    <>
      <button
        className={`${color}`}
        onClick={handleShow}
        disabled={disabled}
        style={{ pointerEvents: "auto", width: "120px" }}
        data-title={
          disabled &&
          (rowtrx.refundStatus === "REFUND_CANCELED"
            ? rowtrx.refundAcceptanceComment
            : null)
        }
      >
        {t(txt)}
      </button>

      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <BlockUI blocking={loaderShow} title="submitting" />
        <Modal.Header closeButton>
          <Modal.Title>Send Web form to customer for Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {serverErrorMessage && (
            <div className="alert alert-danger" role="alert">
              {serverErrorMessage}
            </div>
          )}
          {initiateData.refundAmountFiat > rowtrx.balanceAmountFiat && (
            <div className="alert alert-danger" role="alert">
              Refund Amount should not be more than received amount
            </div>
          )}
          <Form autoComplete="off">
            <Form.Group className="mb-3">
              {formErrorMessage && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{ textAlign: "center" }}
                >
                  {formErrorMessage}
                </div>
              )}
              {/* <Form.Label>Refund Amount in {rowtrx.fiatAsset}</Form.Label>
              <Form.Control
                type="text"
                placeholder={`Refund Amount in ${rowtrx.fiatAsset}`}
                value={initiateData.refundAmountFiat}
                onChange={(e) => storeData(e, "refundAmount")}
              /> */}
              <Form.Label>
                Maximum Refund Eligibility in{" "}
                {rowtrx.fiatAsset && rowtrx.fiatAsset}
              </Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder={`Received Fiat Amount in ${rowtrx?.fiatAsset}`}
                value={
                  rowtrx?.balanceAmountFiat > 0
                    ? rowtrx?.balanceAmountFiat
                    : rowtrx?.totalFiatReceived
                }
                onChange={(e) => storeData(e, "refundAmount")}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Refund Amount in {rowtrx.fiatAsset && rowtrx.fiatAsset}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={`Refund Amount in ${rowtrx?.fiatAsset}`}
                value={initiateData.refundAmountFiat}
                disabled={
                  rowtrx.refundStatus == "REFUND_EXPIRED" ||
                  rowtrx.refundStatus === "REFUND_CANCELED" ||
                  rowtrx.refundStatus === "REFUND_FALIED"
                    ? true
                    : false
                }
                // value={rowtrx.refundAmount}
                onChange={(e) => storeData(e, "refundAmountFiat")}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Refund Mode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Refund Mode"
                autoComplete="off"
                value={
                  initiateData.refundAmountFiat <= refundAmountLimit
                    ? "cash"
                    : "wallet"
                }
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Balance Fiat Amount in {rowtrx.fiatAsset && rowtrx.fiatAsset}
              </Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={
                  rowtrx?.balanceAmountFiat - initiateData.refundAmountFiat >= 0
                    ? (
                        rowtrx.balanceAmountFiat - initiateData.refundAmountFiat
                      ).toFixed(2)
                    : ""
                }
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>No. of Times Refund Done</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder={`No. of Times Refund Done`}
                value={rowtrx.numberOfRefunds && rowtrx.numberOfRefunds}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Refund Type</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="Refund Type"
                value={
                  initiateData.refundAmountFiat == rowtrx.totalFiatReceived
                    ? "Full"
                    : "Partial"
                }
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile number</Form.Label>
              <div className="mobileNumberInputs">
                <Form.Select
                  disabled={initiateData.refundAmountFiat <= refundAmountLimit}
                  defaultValue={initiateData.refundUserMobileCountryCode}
                  onChange={(e) => storeData(e, "mobileCode")}
                  ref={inputPhoneCodeRef}
                >
                  <option value="">Code</option>
                  <option value="+91">+91</option>
                  <option value="+971">+971</option>
                  <option value="+61">+61</option>
                </Form.Select>
                <Form.Control
                  ref={inputPhoneRef}
                  type="text"
                  autoComplete="off"
                  value={initiateData.refundUserMobile}
                  placeholder="Mobile number"
                  disabled={initiateData.refundAmountFiat <= refundAmountLimit}
                  onChange={(e) => storeData(e, "mobileNumber")}
                />
              </div>
            </Form.Group>
            {/* <div>OR</div> */}
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                ref={inputEmailRef}
                type="email"
                autoComplete="off"
                value={initiateData.refundUserEmail}
                placeholder="Email Address"
                disabled={initiateData.refundAmountFiat <= refundAmountLimit}
                onChange={(e) => storeData(e, "email")}
              />
            </Form.Group>
            {/* <div>OR</div> */}
            <Form.Group
              className="mb-3"
              style={{ justifyContent: "space-between", display: "flex" }}
            >
              <div>
                <button
                  type="button"
                  className="btn wdz-btn-primary"
                  disabled={
                    initiateData.refundAmountFiat > rowtrx.totalFiatReceived ||
                    initiateData.refundAmountFiat <= refundAmountLimit ||
                    initiateData.refundUserMobile.length > 0 ||
                    initiateData.refundUserEmail.length > 0 ||
                    initiateData.refundUserMobileCountryCode.length > 0
                  }
                  onClick={() => setShowCustomerForm(!showCustomerForm)}
                >
                  Customer`s info Manually
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={
                    initiateData.refundAmountFiat > rowtrx.totalFiatReceived
                  }
                  onClick={() => submitData()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ms-3"
                  onClick={() => clearData()}
                >
                  Clear
                </button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      {showCustomerForm ? (
        <CustomerVeriRefundPopupModal
          rowtrx={props.rowtrx}
          showhide={() => setShowCustomerForm(false)}
          refundableFiatAmount={initiateData.refundAmountFiat}
        />
      ) : null}
    </>
  )
}

export default InitiateRefundPopupModal
